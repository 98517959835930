export default function Location() {
  return (
    <div
      className="bg-dark text-light"
      style={{
        paddingBottom: "10rem",
      }}
      id="location"
    >
      <div className="tmj-section-header">Location</div>

      <div
        className="d-flex flex-row-revers bg-dark row m-auto"
        style={{
          padding: "0 5%",
        }}
      >
        <div
          className="col-12 col-lg-6 p-2 p-lg-5 pt-0 pt-lg-0 mb-5"
          // style={{
          //   flex: "50%",
          //   maxWidth: "50%",
          // }}
        >
          <p className="tmj-section-register-support-paragraph text-light">
            Located in the heart of Dharmawangsa in South Jakarta, The Opus Grand Ballroom is renowned for hosting Jakarta's most prestigious events and festivities. Within its colonial-style structure, The Grand Ballroom features magnificent chandeliers hanging from lofty ceilings. The dancehall boasts a 200 m² melamin acrylic floor and a generous terrace, providing a tranquil spot for relaxation or dining.
            <br />
            <br />
            The Tribrata Darmawangsa <br />
            Jalan Darmawangsa III no. 2 <br />
            Jakarta 12160 <br />
            Indonesia <br />
          </p>
        </div>

        <div
          className="d-flex col-12 col-lg-6 mb-5 justify-content-center overflow-hidden"
          // style={{
          //   flex: "50%",
          //   maxWidth: "50%",
          // }}
        >

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15864.30173245085!2d106.7986652!3d-6.2537921!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f1182c9c42df%3A0x15973de4b1c09abd!2sThe%20Tribrata%20Darmawangsa%20Jakarta!5e0!3m2!1sid!2sid!4v1686902265870!5m2!1sid!2sid"
            width="500"
            height="500"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="location jakarta tango marathon"
          ></iframe>

          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.0737637920256!2d106.79564247587616!3d-6.254012161234426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f173f9cd73bf%3A0xa373a30cebb362de!2sSutasoma%20Hotel!5e0!3m2!1sen!2sid!4v1686276938623!5m2!1sen!2sid"
            width="500"
            height="500"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="location jakarta tango marathon"
          ></iframe> */}
        </div>
      </div>
    </div>
  );
}
