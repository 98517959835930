

import DjBasil from "../assets/cast/basil1.jpg"
import DjCarlos from "../assets/cast/carlos1.jpg"
import DjLloyd from "../assets/cast/lloyd.jpeg"
import DjLloyd2 from "../assets/cast/lloyd2.jpeg"
import DjLuca from "../assets/cast/luca.jpeg"
import Agostina from "../assets/cast/agostina.jpeg"
import Agostina2 from "../assets/cast/agostina2.jpeg"
import Agostina3 from "../assets/cast/agostina3.JPG"
import GuestPerformence from "../assets/cast/guestPerformence.jpeg"
import Fuchong from "../assets/cast/fuchong.jpeg"
import Punto from "../assets/cast/puntoybranca.jpeg"
import Punto2 from "../assets/cast/puntoybranca2.jpeg"

export default function cast () {

  return (
    <div
      className="bg-dark text-light"
      style={{
        paddingBottom: "10rem",
      }}
      id="cast"
    >
      <div
        className="tmj-section-header"
      >
        Cast Tango DJ
      </div>


      <div
        className="containessr"
        style={{
          padding: "0 5%",
        }}
      >
        <div
          className="row"
        >
          {/* <div
            className="col-12 col-md-6 mb-3"
          >
            <div
              className="position-relative"
              style={{
                width: "100%",
                paddingBottom: "100%",
                backgroundImage: `url(${DjBasil})`,
                backgroundSize: "cover",
                backgroundPosition: "center top",
              }}
            >
              <div className="tmj-cast-shadow"/>
              <h1
                className="tmj-cast-title"
              >
                <span
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  TDJ Basil Nadela,
                </span>
                <div>
                  Philippines
                </div>
              </h1>
            </div>
          </div> */}

          <div
            className="col-12 col-md-6 mb-3"
          >
            <div
              className="position-relative"
              style={{
                width: "100%",
                paddingBottom: "100%",
                backgroundImage: `url(${DjLloyd2})`,
                backgroundSize: "cover",
                backgroundPosition: "63% center",
              }}
            >
              <div className="tmj-cast-shadow"/>
              <h1
                className="tmj-cast-title"
              >
                <span
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  TDJ Lloyd Bediones,
                </span>
                <div>
                  Indonesia
                </div>
              </h1>
            </div>
          </div>

          <div
            className="col-12 col-md-6 mb-3"
          >
            <div
              className="position-relative"
              style={{
                width: "100%",
                paddingBottom: "100%",
                backgroundImage: `url(${Fuchong})`,
                backgroundSize: "cover",
                backgroundPosition: "right center",
              }}
            >
              <div className="tmj-cast-shadow"/>
              <h1
                className="tmj-cast-title"
              >
                <span
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  TDJ Fuchong,
                </span>
                <div>
                  Indonesia
                </div>
              </h1>
            </div>
          </div>

          <div
            className="col-12 col-md-6 mb-3"
            style={{
              overflow: "hidden",
            }}
          >
            <div
              className="position-relative"
              style={{
                width: "100%",
                paddingBottom: "100%",
                backgroundImage: `url(${Punto2})`,
                backgroundSize: "112%",
                backgroundPosition: "-12px center",
              }}
            >
              <div className="tmj-cast-shadow"/>
              <h1
                className="tmj-cast-title"
              >
                <span
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  TDJ Punto y Branca,
                </span>
                <div>
                  Argentine
                </div>
              </h1>
            </div>
          </div>

          {/* <div
            className="col-12 col-md-6 mb-3"
          >
            <div
              className="position-relative"
              style={{
                width: "100%",
                paddingBottom: "100%",
                backgroundImage: `url(${DjCarlos})`,
                backgroundSize: "cover",
                backgroundPosition: "center top -50px",
              }}
            >
              <div className="tmj-cast-shadow"/>
              <h1
                className="tmj-cast-title"
                style={{
                  marginTop: "9.5rem",
                }}
              >
                <span
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  TDJ Carlos Churri, 
                </span>
                <div>
                  South Korea
                </div>
              </h1>
            </div>
          </div> */}

          {/* <div
            className="col-12 col-md-6 mb-3"
          >
            <div
              className="position-relative"
              style={{
                width: "100%",
                paddingBottom: "100%",
                backgroundImage: `url(${DjLuca})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
            >
              <div className="tmj-cast-shadow"/>
              <h1
                className="tmj-cast-title"
                style={{
                  marginTop: "7rem",
                }}
              >
                <span
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  TDJ Luca Lamberti,
                </span>
                <div>
                  Italy
                </div>
              </h1>
            </div>
          </div> */}


        </div>

      </div>




      <div
        className="tmj-section-header"
      >
        Cast Performer
      </div>


      <div
        className="containessr"
        style={{
          padding: "0 5%",
        }}
      >
        <div
          className="row"
        >

          <div
            className="col-12 col-md-6 mx-auto mb-3"
          >
            <div
              className="position-relative"
              style={{
                width: "100%",
                paddingBottom: "100%",
                backgroundImage: `url(${Agostina3})`,
                backgroundSize: "cover",
                backgroundPosition: "center top 78%",
              }}
              // style={{
              //   width: "100%",
              //   paddingBottom: "100%",
              //   backgroundImage: `url(${Agostina})`,
              //   backgroundSize: "cover",
              //   backgroundPosition: "center top 40%",
              // }}
            >
              <div className="tmj-cast-shadow"/>
              <h1
                className="tmj-cast-title"
                style={{
                  marginTop: "4rem"
                }}
              >
                <div
                  style={{
                    whiteSpace: "nowrap"
                  }}
                >
                  Sebastian Jimenez Y
                </div>
                <span
                  style={{
                    whiteSpace: "nowrap"
                  }}
                >
                  Agostina Tarchini
                </span>
              </h1>
            </div>
          </div>


        </div>

      </div>

      {/* GUEST PERFORMER */}

      {/* <div
        className="tmj-section-header"
      >
        Guest Performer
      </div> */}

      {/* <div
        className="containessr"
        style={{
          padding: "0 5%",
        }}
      >
        <div
          className="row"
        >

          <div
            className="col-12 col-md-6 mx-auto mb-3"
          >
            <div
              className="position-relative"
              style={{
                width: "100%",
                paddingBottom: "100%",
                backgroundImage: `url(${GuestPerformence})`,
                backgroundSize: "cover",
                backgroundPosition: "center top 40%",
              }}
            >
              <div className="tmj-cast-shadow"/>
              <h1
                className="tmj-cast-title"
                style={{
                  marginTop: "4rem"
                }}
              >
                <div
                  style={{
                    whiteSpace: "nowrap"
                  }}
                >
                  Pablo Martinez Y
                </div>
                <span
                  style={{
                    whiteSpace: "nowrap"
                  }}
                >
                  Irina Baslik
                </span>
              </h1>
            </div>
          </div>


        </div>

      </div> */}

    </div>
  )
}