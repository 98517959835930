import jumbotron from "../components/jumbotron";
export default function FQA() {
  return (
    <>
      {jumbotron()}
      <div
        className="container py-5"
        style={{ minWidth: "100%", backgroundColor: "#eaeaea" }}
      >
        <h2 className="text-center">FQA</h2>
        <div
          className="accordion m-auto container-accordion"
          id="accordionExample"
        >
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                1. Airport
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul className="list-unstyled">
                  <li>- Hanoi Airport (Noi Bai) Vietnam</li>
                  <li>
                    <a href="http://noibaiairport.vn/en"></a>-
                    http://noibaiairport.vn/en
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                2. Taxi from Airport
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul className="list-unstyled">
                  <li>- Price: ~ 250.000VND – 400.000VND</li>
                  <li>
                    - Order in advance:
                    <a href="http://taxinoibaiairports.com/taxi-noi-bai-ha-noi">
                      http://taxinoibaiairports.com/taxi-noi-bai-ha-noi
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                3. How to register for couple
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul className="list-unstyled">
                  <li>- Please register and note your partners name</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                4. City and tour
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul className="list-unstyled">
                  <li>
                    - Half day motobike tour -
                    <a href="https://hanoibackstreettours.com">
                      https://hanoibackstreettours.com
                    </a>
                  </li>
                  <li>- 1 day or 2 days tour</li>
                  <li>Hasia Travel Company -</li>
                  <li>Ms Liên- 0914341137 (English)</li>
                  <li>Ms Trang - 0983841211 (Vietnamese, Spanish)</li>
                  <li>Email: lienhasia@gmail.com</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                5. No cancellation - No refund - No different
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul className="list-unstyled">
                  <li>
                    - Transferable contact organizer provite name/paspport
                    number/ email/ phone number
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
