import CardSchadule from "../components/cardSchadule";
import DemoSchaduleActor from "../assets/demo-schadule.jpg";
import DemoSchaduleActor1 from "../assets/demo-schadule1.jpg";

export default function SchedulesPrices() {
  return (
    <div className="container my-5">
      <CardSchadule
        image={DemoSchaduleActor}
        date={"April 3"}
        heading={"The Care and Feeding of Baby Birds"}
        time={"6:25pm - 7:50pm"}
        story={"Ann Wuehler"}
        director={"Nick Fox"}
        description={
          "Running Time: About 1 hour and 25 minutes. There is no interval."
        }
      />
      <CardSchadule
        image={DemoSchaduleActor1}
        date={"April 4"}
        heading={"The Vampire Carmilla - A Play In One Act"}
        time={"11:45pm - 1:45am"}
        story={"Joseph Sheridan Le Fanu"}
        director={"Melinda Stoker"}
        description={"Running Time: About 2 hours. There is no interval."}
      />
      <h1 className="text-center text-danger">SCHEDULE</h1>
      <h1 className="text-center text-danger">CONTRIBUTION PRICES</h1>
      <div className="container" style={{ marginTop: "10vh" }}>
        <h3 className="text-center text-danger mt-5 mb-4">MARATHON PASS</h3>
        <div className="container text-center bg-dark text-white w-25 py-3">
          <h3>Late Bird</h3>
          <p>15/2/2023-15/3/2023</p>
        </div>
        <div
          className="container text-center w-25 p-5"
          style={{ backgroundColor: "#f7f7f7" }}
        >
          <h1 className="text-primary fw-bolder" style={{ fontSize: "4rem" }}>
            Close
          </h1>
          <p className="fs-5">Package 1</p>
          <button className="btn btn-primary fw-bold">REGISTER NOW</button>
        </div>
      </div>
      <div className="container" style={{ marginTop: "10vh" }}>
        <h3 className="text-center text-danger mt-5 mb-4">
          AFTER MARATHON - LUXURY CRUISE MILONGA - HA LONG BAY
        </h3>
        <div className="container text-center bg-dark text-white w-25 py-3">
          <h3>Early Bird</h3>
          <p>23/11/2022 - 23/12/2022</p>
        </div>
        <div
          className="container text-center w-25 p-5"
          style={{ backgroundColor: "#f7f7f7" }}
        >
          <div className="mb-4">
            <h1
              className="text-warning fw-bolder"
              style={{ fontSize: "4rem", lineHeight: "1px" }}
            >
              Full
            </h1>
            <p>/Person</p>
          </div>
          <p className="fs-5">AFTER MARATHON</p>
          <button className="btn btn-warning text-white fw-bold">
            REGISTER NOW
          </button>
          <p className="fs-6 fw-semibold mt-3">
            3 April to 4 April 2023 <br /> CRUISE MILONGA HA LONG BAY
          </p>
        </div>
      </div>
      <div className="mt-5">
        <h3>Marathon pass – Inclusions</h3>
        <ul className="fs-5 lh-lg">
          <li>Friday milonga, food/drink, international Djs</li>
          <li>
            Saturday out door milonga + Gala dinner milonga + after milonga,
            international Djs
          </li>
          <li>Sunday milonga food/drink, international Djs</li>
        </ul>
        <h3>AFTER MARATHON – CRUISE MILONGA HA LONG BAY</h3>
        <p className="fs-5 lh-lg">2 days 1 night CRUISE MILONGA – Inclusions</p>
        <ul className="fs-5 lh-lg">
          <li>Round trip bus from Ha long (25usd/person)</li>
          <li>2 Milonga with internation Djs</li>
          <li>2-day cruise itinerary and program</li>
          <li>
            1 night in luxury cabins/suites with en suite bathroom, air
            conditioning (SHARE ROOM)
          </li>
          <li>
            1 light breakfast / 1 breakfast / 1 lunch seafood / 1 dinner with
            lobster
          </li>
          <li>Two bottles of water in the cabin daily</li>
          <li>Afternoon tea, canapes & Taichi session</li>
          <li>Onboard insurance, taxes, and service charges</li>
          <li>Entrance and sightseeing fees</li>
        </ul>
        <h3>Exclusions</h3>
        <ul className="fs-5 lh-lg">
          <li>Airfare & airport transfers</li>
          <li>Visa arrangements</li>
          <li>Kayak</li>
          <li>Spa treatment services</li>
          <li>Beverages and tobacco</li>
          <li>Tips and other expenses not mentioned in inclusions</li>
        </ul>
        <hr style={{ marginTop: "10vh", marginBottom: "10vh" }} />
        <h3>Term & condition: </h3>
        <p className="fs-5 lh-lg">
          *** “First-come first-serve” doesn’t apply for this event, decision
          will be made by organizer and we can only accept 200 dancers from all
          over the world. Therefore, we will balance genders accordingly in
          order to create an extremely friendly atmosphere for the event <br />
          *** No walk-in. <br /> *** No Transferred Permitted without checking
          with the organizers first. <br /> *** Event Cancelation – NO REFUND
          applied but can be transferred with confirmation by the organizers{" "}
          <br /> *** Noted: special offer will be applied to local supporting
          team and event’s sponsor by invitation letter only and confirmed
          before registration day <br />
          <br /> **Hotel booking: <br /> – Reservation to organizers <br /> – NO
          CANCELLATION from 1st January 2023 <br /> ** Cruise milonga: <br /> –
          No Cancellation after register but transferable with organizers
          confirmation <br /> – Please understand the cruise policy, overseas
          nationality will add 10 usd service charge. <br /> ***Payment methods:
          <br /> – The event will have representative in different countries
          (Organizer will instruct you indivially) <br /> – CASH & USD ONLY{" "}
          <br />
          <br /> Notes <br /> – We are seeking for your understanding of
          Transfer fee will be added accordingly <br /> – At-door handling fee:
          $10/package (you are encouraged to make payment in advance for
          logistic arrangement, <br /> – Cash delivered: please contact
          organizer for arrangement, in special case, please email or air ticket
          to confirm your reservation) <br /> ***Payment conditions: <br /> 1.
          Registration is confirmed when we arrange payment method. As limited
          number of participants, the organizers will prioritize participants
          who have made payment. <br /> 2. The organizers reserve the right to
          terminate unpaid registration if we don’t receive any feedback <br />{" "}
          3. Registration is transferable <br /> ***Other important things to
          note: <br /> 1. Light snacks, food and beverages will be provided at
          the Vietnam Tango Marathon. Please help to dispose of any waste before
          leaving the venues. No alcohol along! <br /> 2. Please observe good
          milonga etiquette at the marathon such as the use of Cabaceo and
          respecting the Ronda (e.g. please do not step backward against the
          line of dance or zig-zag between lanes). <br /> 3. The organizer
          reserves the right to remove any participant(s) from the event if they
          are deemed to have disrupted the event or displayed behavior that
          compromises the safety of other participants. No refund will be
          issued. <br /> 4. The organizer shall not be held responsible for any
          damage, loss or property and/or injury incurred as a result of you
          attending the event. <br /> 5. The event might change the venue due to
          instruction from Government but we promise to take good care of you
          for your best experiences with us. <br />
          *** We agree all the terms & condition of term & condition above
        </p>
      </div>
      <div className="w-100 my-5">
        <button className="btn btn-danger btn-lg m-auto d-block">
          REGISTER NOW
        </button>
      </div>
    </div>
  );
}
