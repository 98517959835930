import ImageAbout from "../assets/image-about.jpg";
export default function About() {
  return (
    <div className="container mt-1 mb-5">
      <div>
        <img src={ImageAbout} alt="about image" className="img-fluid" />
        <div
          className="container container-about py-5 px-4 mt-3"
          style={{ backgroundColor: "#eaeaea" }}
        >
          <h2 className="text-danger">TANGO SALIDA DE SOL</h2>
          <p>
            We are, Hue Tango leader, Hanoi Tango leader and Saigon Tango
            Founder (Ms Alice (Tango Vietnam) as a feature organizer) who have a
            goal to create a compassion environment for tango lovers around the
            world to meet Vietnam tango lovers, to share your tango, share your
            passion to young tango community and to explore different cities
            among Vietnam each year for the most pleasurable Tango vacation.
            <br />
            <br />
            With more than 30 hours of tango with international Djs, with cruise
            milonga, traditional milonga with food and drink, Tango salida de
            sol philosophy is embracing with heart and respecting tango culture
            so space will be limited to only the most respectful 250 dancers,
            lead/follow ratios will be maintained and the traditions of the
            milonga are encouraged.
            <br />
            <br />
            So more than just dancing, we aim to create the cozy and friendly
            atmosphere in milonga and outside milonga. Warm welcome to enjoy the
            heart of Vietnam and vietnam tango community all in one place at
            once time and with one purpose to share your passion with each other
            through this beautiful dance.
            <br />
            <br />
            Thank you so much for your best thought about Vietnam Tango Salida
            De Sol from 1st edition 2016 to now
          </p>
        </div>
      </div>
      <div className="text-center mt-3">
        <div className="container-about m-auto">
          <iframe
            src="https://www.youtube.com/embed/WL1MJiVgp3I"
            title="YouTube video player"
            width="100%"
            height="450"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <div className="row flex-lg-row flex-lg-nowrap my-3">
            <div className="col-lg-6">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/msTNahCOMDE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
            <div className="col-lg-6 flex-lg-column text-start">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/buinn6cBLcA"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="row flex-lg-row flex-lg-nowrap">
            <div className="col-lg-6">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/msTNahCOMDE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
            <div className="col-lg-6 flex-lg-column text-start">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/buinn6cBLcA"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-1">
        <div className="container container-about mt-3">
          <p>
            <span className="fw-bold">TANGO SALIDA DE SOL TEAM</span>
          </p>
          <p>Hotline: MS Alice +84 35 60 20 135 ( Whatsapp/zalo )</p>
          <p>
            Email:
            <a
              href="mailto:vietnamtangomarathon@gmail.com"
              className="text-decoration-none"
            >
              vietnamtangomarathon@gmail.com
            </a>
            /
            <a
              href="mailto:tangosalidadesol@gmail.com"
              className="text-decoration-none"
            >
              tangosalidadesol@gmail.com
            </a>
            | <br />
            Fanpage :
            <a
              href="https://www.facebook.com/vietnamtangomarathon?mibextid=ZbWKwL"
              className="text-decoration-none"
            >
              Vietnam TANGO Marathon
            </a>
            | Group:
            <a
              href="https://www.facebook.com/groups/vntangomarathon/"
              className="text-decoration-none"
            >
              VIETNAM TANGO SALIDA DEL SOL
            </a>
            | <br />
            Event:
            <a
              href="https://www.facebook.com/events/858352528962309/?acontext=%7B%22ref%22%3A%2252%22%2C%22action_history%22%3A%22[%7B%5C%22surface%5C%22%3A%5C%22share_link%5C%22%2C%5C%22mechanism%5C%22%3A%5C%22share_link%5C%22%2C%5C%22extra_data%5C%22%3A%7B%5C%22invite_link_id%5C%22%3A820355495939321%7D%7D]%22%7D"
              className="text-decoration-none"
            >
              here
            </a>
          </p>
          <p>Website: https://tangosalidadesol.com/</p>
        </div>
      </div>
      <div className="w-100">
        <button className="btn btn-danger btn-lg m-auto d-block">
          REGISTER NOW
        </button>
      </div>
    </div>
  );
}
