import Logo from "./logo";
import { useState } from "react";
import LogoImg from "../assets/logo.png";

export default function Footer() {
  const [message, setMessage] = useState("");

  // function
  const sendMessage = () => {
    const url = `https://wa.me/+6287811021160?text=${message.replace(/ /g, "%20")}`;
    window.open(url, "_blank");
  }
  return (
    <div>
      <div className="bg-dark text-ligh text-montserrat p-5 pt-4 pb-2 tmj-footer">
        <div className="row">
          <div
            className="col-lg-5 col-sm-12 pb-2 pt-4 tmj-footer-logo"
          >
            <img
              src={LogoImg}
              alt="logo"
              className="tmj-footer-logo-img"
            />
            {/* <Logo width={"250px"} /> */}
          </div>
          <div className="col-lg-3 col-sm-12 pb-5">
            <div className="tmj-footer-header">Follow Us</div>
            <div
              className="tmj-footer-org tmj-footer-link mb-3"
            >
              <a
                href="https://www.instagram.com/urban_tango.jkt/" target="_blank"
                className="d-inline-block position-relative tmj-bg-faded hover rounded-circle me-3"
                style={{
                  width: "37px",
                  height: "37px",
                }}
              >
                <i
                  className="fa-brands fa-instagram tmj-middle"
                  style={{
                    fontSize: "18px",
                    // top: "57%",
                    // left: "52%",
                    margin: "0",
                  }}
                ></i>
              </a>
              Urban Tango Jakarta
            </div>
            <div
              className="tmj-footer-org tmj-footer-link mb-3"
            >
              {/* <div
                className="d-inline-block position-relative tmj-bg-faded tmj-footer-link hover rounded-circle me-3"
                style={{
                  width: "37px",
                  height: "37px",
                }}
              >
                <i
                  // className="facebook icon tmj-middle"
                  // className="bi bi-facebook tmj-middle"
                  className="fa-brands fa-facebook-f tmj-middle"
                  style={{
                    fontSize: "18px",
                    // top: "57%",
                    // left: "52%",
                    margin: "0",
                  }}
                ></i>
              </div> */}

              <a
                href="https://www.instagram.com/tangolicious.jakarta/" target="_blank"
                className="d-inline-block position-relative tmj-bg-faded tmj-footer-link hover rounded-circle me-3"
                style={{
                  width: "37px",
                  height: "37px",
                }}
              >
                <i
                  className="fa-brands fa-instagram tmj-middle"
                  style={{
                    fontSize: "18px",
                  }}
                ></i>
              </a>
              Tangolicious
            </div>
          </div>
          <div className="col-lg-4 col-sm-12">
            <div className="tmj-footer-header">Question</div>
            {/* <div className="tmj-footer-header">Registration and Payment Confirmation</div> */}
            <div className="tmj-container-textarea">
              {/* <div className="input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    aria-label="Dollar amount (with dot and two decimal places)"
                                    placeholder="Your Question"
                                />
                            </div>
                            <button type="button" className="btn btn-warning">Send By Whatsapp</button> */}

              {/* <div className="input-group mb-3 input-group-lg">
                                <input type="text" className="form-control" placeholder="Your Question" aria-label="Recipient's username" aria-describedby="button-addon2"/>
                                <button className="btn btn-outline-warning" type="button" id="button-addon2">Send</button>
                            </div> */}

              {/* <div className="input-group input-group-lg">
                                <input
                                    type="text"
                                    className="form-control"
                                    style={{
                                        minHeight: "80px",
                                        verticalAlign: "text-top",
                                    }}
                                    aria-label="Sizing example input"
                                    aria-describedby="inputGroup-sizing-lg"
                                />
                                <span className="input-group-text" id="inputGroup-sizing-lg">Large</span>
                            </div> */}

              <div>
                <input
                  type="textarea"
                  className="tmj-textarea-2"
                  placeholder="Your message..."
                  onChange={(e) => {setMessage(e.target.value)}}
                />
                <button className="tmj-btn-outline-footer-2 mt-0"
                  // href="https://wa.me/+6287800102507?text=I'm%20interested%20in%20your%20car%20for%20sale"
                  onClick={()=> {sendMessage()}}
                >
                  Send via Whatsapp
                </button>
              </div>

              {/* <div>
                                <input
                                    type="textarea"
                                    className="tmj-textarea bg-dark"
                                    placeholder="Your message"
                                />
                                <button
                                    className="tmj-btn-outline-footer mt-3"
                                >
                                    Send Message
                                </button>

                            </div> */}

              {/* <div
                                className="tmj-textarea-test mt-5"
                            ></div> */}
            </div>
          </div>

          <div className="col-lg-7 col-sm-12 text-light d-none">
            <div className="mb-4">
              <h2 className="reguler mb-3 fs-4">Contact Us</h2>
              <div className="row">
                <div className="col-sm-12 col-lg-4 mb-2 light">
                  Email test@gmail.com
                </div>
                <div className="col-sm-12 col-lg-4 light">WA +6281234567</div>
              </div>
              {/* <span className="fs-5">
                                email test@gmail.com
                            </span>
                            <span className="ms-5 fs-5">
                                wa +6281234567
                            </span> */}
              {/* <div className="fs-5 d-flex">
                                <div style={{width: "80px"}}>
                                    email
                                </div>
                                <div className="flex-fill">
                                    test@gmail.com
                                </div>
                            </div>
                            <div className="fs-5 d-flex">
                                <div style={{width: "80px"}}>
                                    wa
                                </div>
                                <div className="flex-fill">
                                +6281234567
                                </div>
                            </div> */}
            </div>
            <div className="mb-4">
              <h2 className="reguler mb-3 fs-4">Follow Us</h2>
              <div>
                <div className="d-inline me-5">
                  <i className="bi bi-facebook fs-4 me-3"></i>
                  <span className="fs-5 light">Facebook</span>
                </div>
                <div className="d-inline">
                  <i className="bi bi-instagram fs-4 me-3"></i>
                  <span className="fs-5 light">Instagram</span>
                </div>
              </div>
              {/* <a className="nav-link" href="#">Features</a> */}
            </div>
          </div>
        </div>
        <div className="text-center text-muted tmj-footer-end">
          Copyright © 2023 All rights reserved
        </div>
      </div>
    </div>
  );
}
