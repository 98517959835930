import Schedules from "./schedules";
import Notice from "../components/notice";
import Banner from "../components/banner";
import CardSchadule from "../components/cardSchadule";
import Profile from "../assets/demo-schadule.jpg";
import Hotel from "../components/hotel";
import Register from "../components/register";
import Location from "../components/location";
import FAQ from "../components/FAQ";
import Cast from "./cast";
import Host from "../components/host";
import Countdown from "../components/countdown";
import Workshop from "../components/workshop";

export default function Home() {
  return (
    <>
      {/* <Notice/> */}
      <Countdown />

      <Banner />

      {/* SCHEDULES */}
      <Schedules />

      {/* <CardSchadule
        image={Profile}
        date="New Concept"
        heading="Taxi Dancers"
        time="6:25 pm to 7:50pm . 1h 25"
        story="Ann Wuehler"
        director="Nick Fox"
        description="Running Time: About 1 hour 25 minutes. There is no interval"
      /> */}

      <Cast />

      <Hotel />

      <Location />

      {/* <Workshop /> */}

      <Register />

      <FAQ />

      <Host />

      {/* <div className="pb-5 bg-dark" /> */}
    </>
  );
}
