import logo from './logo.svg';
import './App.css';
import Navtop from "./components/navtop"
import Footer from "./components/footer"
import Flyer from "./routes/flyer"
import Host from "./components/host"


import { Outlet } from "react-router-dom";

export default function App() {
  return (
    <>
      <Navtop/>
      <Outlet/>
      <Footer/>

      {/* <Flyer/>
      <Host/> */}
    </>
  );
}
