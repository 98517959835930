import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import App from './App';
import Contact from "./routes/contact";
import Home from "./routes/home"
import About from "./routes/about"
import SchedulesPrices from './routes/schedules-prices';
import Hotels from './routes/hotels';
import FQA from './routes/fqa';
import ContactUs from './routes/contact-us';
import Register from './routes/register';
import ErrorPage from "./error-page";
import reportWebVitals from './reportWebVitals';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/schedules-prices",
        element: <SchedulesPrices />,
      },
      {
        path: "/hotels",
        element: <Hotels />,
      },
      {
        path: "/fqa",
        element: <FQA />,
      },
      {
        path: "/contacts",
        element: <ContactUs />,
      },
      {
        path: "/register",
        element: <Register />,
      },
    ],
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
