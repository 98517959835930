import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Link } from "react-scroll";
import Logo from "./logo";
import LogoImg from "../assets/logo-image.png";
import LogoText from "../assets/logo-text.png";

export default function Navtop() {
  let location = useLocation();
  const ref = useRef();
  let [width, setWidth] = useState(0);

  useEffect(() => {
    // when the component gets mounted
    setWidth(window.innerWidth);
    // to handle page resize
    const getwidth = () => {
      // console.log("width is ",ref.current.clientWidth);
      // setWidth(ref.current.offsetWidth);
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", getwidth);
    // remove the event listener before the component gets unmounted
    return () => window.removeEventListener("resize", getwidth);
  }, []);

  // CLASS CHECK IF LINK IS ACTIVE
  let is_active = (page) => {
    if (location.pathname == page) {
      return " active";
    } else {
      return "";
    }
  };

  let is_menu_collapsed = width < 992;

  // console.log("width is ", width);
  // console.log("is menu collapsed ", is_menu_collapsed);

  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark bg-dark ps-4 pe-4 text-montserrat reguler pt-3 pb-3"
      style={{
        fontSize: "18px",
      }}
    >
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          <div>
            <img
              src={LogoImg}
              alt="logo-image"
              className="tmj-navtop-logo-img"
            />
            <img
              src={LogoText}
              alt="logo-text"
              className="tmj-navtop-logo-text"
            />
          </div>
          {/* <Logo width={"130px"} /> */}
          {/* <img
            src={Logo}
            alt="logo"
            style={{
                // width: "100%",
                height: "150px",
                marginLeft: "30px",
                objectFit: "cover",
                objectPosition: "center",
            }}
          /> */}
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse flex-row-reverse"
          id="navbarNavAltMarkup"
        >
          <div className="navbar-nav text-nowrap align-items-center">
            {/* <a className="nav-link me-4 active" aria-current="page" href="#">Home</a> */}

            {/* <Link
              to={`banner`}
              className={"nav-link me-3" + is_active("/")}
              aria-current="page"
              style={{
                cursor: "pointer",
              }}
            >
              {is_menu_collapsed ? (
                <div
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNavAltMarkup"
                >
                  Home
                </div>
              ) : (
                <div>Home</div>
              )}
            </Link> */}

            <Link
              to={`schedules`}
              smooth={true}
              className={"nav-link me-3" + is_active("/about")}
              aria-current="page"
              activeClass="nav-link"
              style={{
                cursor: "pointer",
              }}
            >
              {is_menu_collapsed ? (
                <div
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNavAltMarkup"
                >
                  Schedules
                </div>
              ) : (
                <div>Schedules</div>
              )}
            </Link>

            <Link
              to={`cast`}
              smooth={true}
              className={"nav-link me-3" + is_active("/schedules-prices")}
              aria-current="page"
              style={{
                cursor: "pointer",
              }}
            >
              {is_menu_collapsed ? (
                <div
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNavAltMarkup"
                >
                  Cast
                </div>
              ) : (
                <div>Cast</div>
              )}
            </Link>

            <Link
              to={`hotels`}
              smooth={true}
              className={"nav-link me-3" + is_active("/hotels")}
              aria-current="page"
              style={{
                cursor: "pointer",
              }}
            >
              {is_menu_collapsed ? (
                <div
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNavAltMarkup"
                >
                  Hotels
                </div>
              ) : (
                <div>Hotels</div>
              )}
            </Link>

            <Link
              to={`location`}
              smooth={true}
              className={"nav-link me-3" + is_active("/location")}
              aria-current="page"
              style={{
                cursor: "pointer",
              }}
            >
              {is_menu_collapsed ? (
                <div
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNavAltMarkup"
                >
                  Location
                </div>
              ) : (
                <div>Location</div>
              )}
            </Link>

            <Link
              to={`fqa`}
              smooth={true}
              className={"nav-link me-3" + is_active("fqa")}
              aria-current="page"
              style={{
                cursor: "pointer",
              }}
            >
              {is_menu_collapsed ? (
                <div
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNavAltMarkup"
                >
                  FQA
                </div>
              ) : (
                <div>FQA</div>
              )}
            </Link>

            {/* <Link
              to={`/contacts`}
              className={"nav-link me-3" + is_active("/contacts")}
              aria-current="page"
            >
              {
                is_menu_collapsed
                ? 
                <div
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNavAltMarkup"
                >
                  Contact Us
                </div>
                :
                <div>
                  Contact Us
                </div>
              }
            </Link> */}

            <Link
              to={`register`}
              smooth={true}
              className={
                "nav-link tmj-btn-primary text-body" +
                is_active("/register")
              }
              aria-current="page"
              style={{
                cursor: "pointer",
              }}
            >
              {is_menu_collapsed ? (
                <div
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNavAltMarkup"
                >
                  Register
                </div>
              ) : (
                <div>Register</div>
              )}
            </Link>

            {/* <a className="nav-link me-4 disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a> */}
          </div>
        </div>
      </div>
    </nav>
  );
}
