import ImageHotel1 from "../assets/hotel1.jpg";
import ImageHotel2 from "../assets/hotel2.jpg";
import ImageHotel3 from "../assets/hotel3.jpg";
import ImageHotel4 from "../assets/hotel4.jpg";
import ImageHotel5 from "../assets/hotel5.jpg";
export default function Hotels() {
  return (
    <>
      <div className="container-slide-show">
        <div className="wrapper-slide-show">
          <img src={ImageHotel1} />
          <img src={ImageHotel2} />
          <img src={ImageHotel3} />
          <img src={ImageHotel4} />
        </div>
      </div>
      <div className="container mt-5">
        <div>
          <h2 className="text-center">
            AFTER MARATHON - LUXURY CRUISE MILONGA 2 DAYS 1 NIGHT
          </h2>
          <p className="fw-bold fs-5 mt-5">
            Time: 3 April 2023 – 4 April 2023
            <br />
            5 star Ambassador Cruise
            <br />
            HA LONG BAY
          </p>
        </div>
        <div className="container text-center mt-">
          <div className="row flex-lg-row flex-lg-nowrap">
            <div className="col-lg-6">
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                    style={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                    }}
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                    style={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                    }}
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                    style={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                    }}
                  ></button>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      src={ImageHotel1}
                      className="d-block w-100"
                      alt="image hotel"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={ImageHotel2}
                      className="d-block w-100"
                      alt="image hotel"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={ImageHotel3}
                      className="d-block w-100"
                      alt="image hotel"
                    />
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div className="col-lg-6 flex-lg-column text-start mt-lg-0 mt-4">
              <p>
                <span className="fw-bold">Ambassador deluxe room</span>: 30 sqm{" "}
                <br />
                Twins or Double for 2 persons ( share room )
              </p>
            </div>
          </div>
        </div>
        <div className="container text-center mt-5">
          <h2 className="mb-4">EVENT HOTEL - FOREIGN RELATIONS HOTEL</h2>
          <div className="row flex-lg-row flex-lg-nowrap">
            <div className="col-lg-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d476703.0842484484!2d105.8596!3d21.022967!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135abeeb93e62af%3A0xe314d26aab2dd510!2zMzNDIFBo4bqhbSBOZ8WpIEzDo28sIFBoYW4gQ2h1IFRyaW5oLCBIb8OgbiBLaeG6v20sIEjDoCBO4buZaSwgVmlldG5hbQ!5e0!3m2!1sen!2sus!4v1678608471114!5m2!1sen!2sus"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="map"
              ></iframe>
            </div>
            <div className="col-lg-6 flex-lg-column text-start">
              <img
                src={ImageHotel5}
                alt="hotel"
                style={{
                  width: "100%",
                  height: "450px",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </div>
          </div>
        </div>
        <div className="container text-center my-5">
          <div className="row flex-lg-row flex-lg-nowrap">
            <div className="col-lg-6">
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                    style={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                    }}
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                    style={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                    }}
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                    style={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                    }}
                  ></button>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      src={ImageHotel1}
                      className="d-block w-100"
                      alt="image hotel"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={ImageHotel2}
                      className="d-block w-100"
                      alt="image hotel"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={ImageHotel3}
                      className="d-block w-100"
                      alt="image hotel"
                    />
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div className="col-lg-6 flex-lg-column text-start mt-lg-0 mt-4">
              <p>
                <span className="fw-bold">
                  Delux double / twin for 2 persons
                </span>
                : 58usd/night <br />
                Address: 33C Pham Ngu Lao Street, Hoan Kiem District, Hanoi
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
