import ProfilePicture from "../assets/dummy-profile-picture.jpg";
import Logo from "../assets/logo.png";
export default function jumbotron() {
  return (
    <div className="container-jumbotron">
      <div className="container-heading">
        <div className="container-left-heading">
          <h1>Tango Salida De Sol</h1>
        </div>
        <div className="container-right-heading">
          <div className="container-text">
            <div className="text">
              <h1>VIETNAM</h1>
              <h1>TANGO MARATHON</h1>
            </div>
            <div className="text-2">
              <h1>Special Edition</h1>
              <h1>2023</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-profile">
        <div className="container-left-profile">
          <div className="profile">
            <img src={ProfilePicture} alt="profile" />
            <h5>DjsJAY</h5>
            <p>KOREA</p>
          </div>
          <div className="profile">
            <img src={ProfilePicture} alt="profile" />
            <h5>DjsJAY</h5>
            <p>KOREA</p>
          </div>
          <div className="profile">
            <img src={ProfilePicture} alt="profile" />
            <h5>DjsJAY</h5>
            <p>KOREA</p>
          </div>
          <div className="profile">
            <img src={ProfilePicture} alt="profile" />
            <h5>DjsJAY</h5>
            <p>KOREA</p>
          </div>
          <div className="profile">
            <img src={ProfilePicture} alt="profile" />
            <h5>DjsJAY</h5>
            <p>KOREA</p>
          </div>
        </div>
        <div className="container-right-profile">
          <div className="text">
            <h2>Hanoi Capital</h2>
            <h1>
              31<sup>st</sup> MARCH - 3<sup>rd</sup> APRIL
            </h1>
          </div>
        </div>
      </div>
      <div className="container-text">
        <p>Organizer : Alice tango vietnam</p>
        <p>
          Hotline: MS Alice +84 35 60 20 135 ( Whatsapp/zalo ) <br />
          Email: vietnamtangomarathon@gmail.com / tangosalidadesol@gmail.com
        </p>
        <p>
          Fb: vietnam tango marathon <br />
          Website: https://tangosalidadesol.com/
        </p>
      </div>
      <div className="container-logo">
        <img src={Logo} alt="logo" />
        <img src={Logo} alt="logo" />
        <img src={Logo} alt="logo" />
        <img src={Logo} alt="logo" />
      </div>
    </div>
  );
}
