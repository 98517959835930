import { useState, useEffect } from "react";
import { getRemainingTimeUntilMsTimestamp } from "../utils/countdown";

const defaultRemainingTime = {
  seconds: "00",
  minutes: "00",
  hours: "00",
  days: "00",
};

export default function Countdown() {
  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(1730977200000) // Timestamp for 7'th nov 2024
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  function updateRemainingTime(countdown) {
    setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
  }
  return (
    <div
      className="text-light"
      style={{
        background: "rgba(121, 90, 57, 1)",
      }}
    >
      <div className="tmj-notice">
        {`
          ${remainingTime.days} Days Remaining to
        `}
        {/* {`
          ${remainingTime.days} days :
          ${remainingTime.hours} hours :
          ${remainingTime.minutes} minutes :
          ${remainingTime.seconds} seconds
        `} */}
      </div>
    </div>
  );
}
