export default function FAQ() {
  return (
    <div
      className="bg-dark text-light"
      style={{
        paddingBottom: "10rem",
      }}
      id="fqa"
    >
      <div className="tmj-section-header">FQA</div>

      <div
        className="bg-dark d-flex flex-column flex-lg-row-reverse m-auto"
        style={{
          padding: "0 5%",
        }}
      >
        <div
          className="accordion m-auto container-accordion text-white"
          id="accordionExample"
        >
          <div
            className="accordion-item"
            style={{
              backgroundColor: "#212529",
              border: "none",
            }}
          >
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed tmj-title-accordion"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                style={{
                  backgroundColor: "#212529",
                  color: "#fff",
                }}
              >
                1. How to pay
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul className="list-unstyled tmj-paragraph-accordion">
                  <li>We will connect with you via WhatsApp to verify the payment. Kindly ensure that the bank details provided below are accurate.</li>
                </ul>
                {/* <p>We will connect with you via WhatsApp to verify the payment. Kindly ensure that the bank details provided below are accurate.</p> */}
                <div>Bank BCA KCP Fatmawati</div>
                <div>Suzy Herawati</div>
                <div>2185080305</div>
                <div>swift code: CENAIDJA</div>
              </div>
            </div>
          </div>
          <div
            className="accordion-item"
            style={{
              backgroundColor: "#212529",
              border: "none",
            }}
          >
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed tmj-title-accordion"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
                style={{
                  backgroundColor: "#212529",
                  color: "#fff",
                }}
              >
                2. Are tickets refundable?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul className="list-unstyled tmj-paragraph-accordion">
                  <li>All tickets are non-refundable.</li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div
            className="accordion-item"
            style={{
              backgroundColor: "#212529",
              border: "none",
            }}
          >
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed tmj-title-accordion"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                style={{
                  backgroundColor: "#212529",
                  color: "#fff",
                }}
              >
                1. Airport
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul className="list-unstyled tmj-paragraph-accordion">
                  <li>- Hanoi Airport (Noi Bai) Vietnam</li>
                  <li>
                    <a href="http://noibaiairport.vn/en"></a>-
                    http://noibaiairport.vn/en
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          {/* <div
            className="accordion-item"
            style={{
              backgroundColor: "#212529",
              border: "none",
            }}
          >
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed tmj-title-accordion"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
                style={{
                  backgroundColor: "#212529",
                  color: "#fff",
                }}
              >
                2. Taxi from Airport
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul className="list-unstyled tmj-paragraph-accordion">
                  <li>- Price: ~ 250.000VND – 400.000VND</li>
                  <li>
                    - Order in advance:
                    <a href="http://taxinoibaiairports.com/taxi-noi-bai-ha-noi">
                      http://taxinoibaiairports.com/taxi-noi-bai-ha-noi
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}

          {/* <div
            className="accordion-item"
            style={{
              backgroundColor: "#212529",
              border: "none",
            }}
          >
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed tmj-title-accordion"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
                style={{
                  backgroundColor: "#212529",
                  color: "#fff",
                }}
              >
                3. How to register for couple
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul className="list-unstyled tmj-paragraph-accordion">
                  <li>Kindly register and record your partner's name, kindly acknowledge that the entrance fee is automatically reserved for two individuals.</li>
                </ul>
              </div>
            </div>
          </div> */}

          {/* <div
            className="accordion-item"
            style={{
              backgroundColor: "#212529",
              border: "none",
            }}
          >
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed tmj-title-accordion"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="true"
                aria-controls="collapseFour"
                style={{
                  backgroundColor: "#212529",
                  color: "#fff",
                }}
              >
                4. Can I buy entrance ticket upon arrival?
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul className="list-unstyled tmj-paragraph-accordion">
                  <li>Yes, simply notify the front desk to purchase entrance ticket.</li>
                  <li
                    style={{
                      marginTop: "10px"
                    }}
                  >Kindly be aware that the entrance fee for on-site purchase is <span style={{fontWeight: "600"}}>USD 85</span>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}

          {/* <div
            className="accordion-item"
            style={{
              backgroundColor: "#212529",
              border: "none",
            }}
          >
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button collapsed tmj-title-accordion"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="true"
                aria-controls="collapseFour"
                style={{
                  backgroundColor: "#212529",
                  color: "#fff",
                }}
              >
                4. City and tour
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul className="list-unstyled tmj-paragraph-accordion">
                  <li>
                    - Half day motobike tour -
                    <a href="https://hanoibackstreettours.com">
                      https://hanoibackstreettours.com
                    </a>
                  </li>
                  <li>- 1 day or 2 days tour</li>
                  <li>Hasia Travel Company -</li>
                  <li>Ms Liên- 0914341137 (English)</li>
                  <li>Ms Trang - 0983841211 (Vietnamese, Spanish)</li>
                  <li>Email: lienhasia@gmail.com</li>
                </ul>
              </div>
            </div>
          </div> */}

          {/* <div
            className="accordion-item"
            style={{
              backgroundColor: "#212529",
              border: "none",
            }}
          >
            <h2 className="accordion-header" id="headingFive">
              <button
                className="accordion-button collapsed tmj-title-accordion"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="true"
                aria-controls="collapseFive"
                style={{
                  backgroundColor: "#212529",
                  color: "#fff",
                }}
              >
                5. No cancellation - No refund - No different
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul className="list-unstyled tmj-paragraph-accordion">
                  <li>
                    - Transferable contact organizer provite name/paspport
                    number/ email/ phone number
                  </li>
                </ul>
              </div>
            </div>
          </div> */}

        </div>
      </div>
    </div>
  );
}
