import React from "react";

export default function CardSchadule({
  image,
  date,
  heading,
  time,
  story,
  director,
  description,
}) {
  return (
    <div
      className="pt-3 bg-dark"
      style={{
        paddingLeft: "15%",
        paddingRight: "3rem",
        position: "relative",
        paddingBottom: "8rem",
      }}
    >
      <div className="d-flex justify-content-end align-items-center gap-5 tmj-profile-text-date">
        <h3 className="text-date">{date}</h3>
        <div className="decoration"></div>
      </div>
      <div className="d-flex w-100 my-5 card-container">
        {/* <img
          src={image}
          alt={`actor`}
          className={"d-none d-md-block img-schadule"}
        /> */}
        <div
          className="ms-3 p-md-5 p-sm-5 px-3 d-flex w-100 my-5 justify-content-center flex-column position-relative"
          style={{
            paddingTop: "4rem",
            paddingBottom: "4rem",
          }}
        >

          {/* <div
            className="d-block d-md-none text-center"
          >
            <img
              src={image}
              alt={`actor`}
              className={"w-100 m-auto"}
              style={{
                objectFit: "cover",
                objectPosition: "center",
                maxHeight: "150px",
              }}
            />
          </div> */}

          <h1 className="text-heading">{heading}</h1>
          {/* <h3 className="text-normal">{time}</h3> */}
          <div className="text-normal lh-base fw-light">
            We offer the services of skilled dancers who are available to partner with individuals at the event. Upon purchasing ticket(s) from us, priced at 15 USD, a taxi dancer will approach you after a cabaceo (non-verbal invitation) and request the ticket before commencing the dance. With each ticket, you gain the opportunity to dance for the duration of one tanda. These tickets serve as both a payment and a gratuity for the taxi dancer, ensuring a smooth and enjoyable dance experience.
          </div>
          <div className="mt-5 px-4 py-3 bg-light">
            <h2 className="text-dark mb-1">Special Offers</h2>
            <p className="text-normal text-dark lh-base fw-light">If you buy 10 tickets, you get a special offer and the purchase is only <strike>150 USD</strike> <span className="fw-bold">140 USD</span></p>
          </div>
          {/* <div className="my-5">
            <h3 className="text-normal">{`Story: ${story}`}</h3>
            <h3 className="text-normal">{`Director: ${director}`}</h3>
          </div>
          <p className="text-small">{description}</p> */}
        </div>
      </div>
    </div>
  );
}
