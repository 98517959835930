import Sutasoma1 from "../assets/sutasoma/sutasoma1.jpg";
import Sutasoma2 from "../assets/sutasoma/sutasoma2.jpg";
import Sutasoma3 from "../assets/sutasoma/sutasoma3.jpg";
import Sutasoma4 from "../assets/sutasoma/sutasoma4.jpg";
import Sutasoma5 from "../assets/sutasoma/sutasoma5.jpg";
import Sutasoma6 from "../assets/sutasoma/sutasoma6.jpg";
import Sutasoma7 from "../assets/sutasoma/sutasoma7.jpg";
import Sutasoma8 from "../assets/sutasoma/sutasoma8.jpg";

import OneZeroOne1 from "../assets/101/101-1.jpeg";
import OneZeroOne2 from "../assets/101/101-2.jpeg";
import OneZeroOne3 from "../assets/101/101-3.jpeg";
import OneZeroOne4 from "../assets/101/101-4.jpeg";

import Grandhika1 from "../assets/grandhika/grandhika-1.jpeg";
import Grandhika2 from "../assets/grandhika/grandhika-2.jpeg";
import Grandhika3 from "../assets/grandhika/grandhika-3.jpeg";
import Grandhika4 from "../assets/grandhika/grandhika-4.jpeg";

import Goodrich1 from "../assets/goodrich/goodrich-1.png";
import Goodrich2 from "../assets/goodrich/goodrich-2.png";
import Goodrich3 from "../assets/goodrich/goodrich-3.png";
import Goodrich4 from "../assets/goodrich/goodrich-4.jpeg";

export default function hotel() {
  return (
    <div
      className="bg-dark text-light"
      style={{
        paddingBottom: "10rem",
      }}
      id="hotels"
    >
      <div className="tmj-section-header">Hotel</div>

      <div className="tmj-section-title">
        Sutasoma Hotel
        <div
          className="text-normal lh-base fw-light mt-3"
          style={{
            padding: "0 10%",
          }}
        >
          Polished, art deco style rooms with marble bathrooms provide Wi-Fi and
          smart TVs, as well as minibars, tea and coffeemaking facilities, and
          safes. Upgraded rooms have sitting areas; suites add separate living
          rooms. In-room dining is available. Breakfast is served in a ritzy
          restaurant that also offers lunch, dinner and afternoon tea. Other
          amenities include a swanky cocktail lounge and a bakery, plus a
          fitness center, an outdoor pool and rooftop event space.
          <br />
          <br />
          Telephone : (021) 5203159
          <br />
          Email : info@thetribrata.com
          <br />
          Address : Jl. Darmawangsa Raya, Pulo, Kec. Kby. Baru, Kota Jakarta
          Selatan, Daerah Khusus Ibukota Jakarta 12160
        </div>
      </div>

      <div
        style={{
          padding: "0 5%",
          marginBottom: "9rem",
        }}
        // className="mb-5"
      >
        <div className="tmj-hotel-img-row">
          <div className="tmj-hotel-img-column">
            <img src={Sutasoma1} />
            <img src={Sutasoma8} />
            <img src={Sutasoma3} />
          </div>

          <div className="tmj-hotel-img-column">
            <img src={Sutasoma5} />
            <img src={Sutasoma7} />
            <img src={Sutasoma2} />
          </div>

          <div className="tmj-hotel-img-column">
            <img src={Sutasoma6} />
          </div>

          <div className="tmj-hotel-img-column">
            <img src={Sutasoma4} />
            <img src={Sutasoma7} />
          </div>
        </div>
      </div>

      <div className="tmj-btn-other-hotel">Hotel Recommendation</div>

      <div className="tmj-section-title">
        101 Darmawangsa Hotel
        <div
          className="text-normal lh-base fw-light mt-3"
          style={{
            padding: "0 10%",
          }}
        >
          <p className="fw-bold">3 minutes walking distance from venue</p>
          Connected to the Darmawangsa Square mall, this cosmopolitan hotel in
          South Jakarta is 2 km from Taman Langsat park and 10 km from Ragunan
          Zoo. Polished rooms with city views provide complimentary Wi-Fi,
          flat-screen TVs, minifridges, and tea and coffeemaking facilities.
          Suites add living rooms. There’s room service. Parking is free. Other
          amenities include a relaxed cafe, a bar, an infinity pool and a spa,
          plus a fitness center with floor-to-ceiling windows.
          <br />
          <br />
          Telephone : (021) 29019101
          <br />
          Email : reservation.darmawangsa@the1o1hotels.com
          <br />
          Address : Jl. Darmawangsa IX No.14, Pulo, Kec. Kby. Baru, Kota Jakarta
          Selatan, Daerah Khusus Ibukota Jakarta 12160
        </div>
      </div>

      <div
        style={{
          padding: "0 5%",
          marginBottom: "9rem",
        }}
      >
        <div className="tmj-hotel-img-row">
          <div className="tmj-hotel-img-column">
            <img src={OneZeroOne1} />
            {/* <img
              src={Sutasoma8}
            />
            <img
              src={Sutasoma3}
            /> */}
          </div>

          <div className="tmj-hotel-img-column">
            <img src={OneZeroOne2} />
            {/* <img
              src={Sutasoma7}
            />
            <img
              src={Sutasoma2}
            /> */}
          </div>

          <div className="tmj-hotel-img-column">
            <img src={OneZeroOne3} />
          </div>

          <div className="tmj-hotel-img-column">
            <img src={OneZeroOne4} />
            {/* <img
              src={Sutasoma7}
            /> */}
          </div>
        </div>
      </div>

      <div className="tmj-section-title">
        Grandhika Iskandarshyah Hotel
        <div
          className="text-normal lh-base fw-light mt-3"
          style={{
            padding: "0 10%",
          }}
        >
          <p className="fw-bold">7 minutes car distance from venue</p>
          The bright, stylish rooms offer Wi-Fi, minibars, and tea and
          coffeemakers. Suites add living areas. Room service is available.
          Amenities include an airy restaurant and a lounge. There are also 2
          outdoor pools (1 for kids) with a terrace and sunloungers. Parking is
          available.
          <br />
          <br />
          Telephone : (021) 29127788
          <br />
          Email : reservation@grandhika-hotel.com
          <br />
          Address : Jl. Iskandarsyah Raya No.65, Melawai, Kec. Kby. Baru, Kota
          Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12160
        </div>
      </div>

      <div
        style={{
          padding: "0 5%",
          marginBottom: "9rem",
        }}
      >
        <div className="tmj-hotel-img-row">
          <div className="tmj-hotel-img-column">
            <img src={Grandhika1} />
            {/* <img
              src={Sutasoma8}
            />
            <img
              src={Sutasoma3}
            /> */}
          </div>

          <div className="tmj-hotel-img-column">
            <img src={Grandhika2} />
            {/* <img
              src={Sutasoma7}
            />
            <img
              src={Sutasoma2}
            /> */}
          </div>

          <div className="tmj-hotel-img-column">
            <img src={Grandhika3} />
          </div>

          <div className="tmj-hotel-img-column">
            <img src={Grandhika4} />
            {/* <img
              src={Sutasoma7}
            /> */}
          </div>
        </div>
      </div>

      <div className="tmj-section-title">
        Goodrich Suites, ARTOTEL Portfolio
        <div
          className="text-normal lh-base fw-light mt-3"
          style={{
            padding: "0 10%",
          }}
        >
          <p className="fw-bold">7 minutes car distance from venue</p>
          Airy suites feature free Wi-Fi, flat-screen TVs and minifridges, as
          well as tea and coffeemaking facilities, sitting areas and safes. All
          have en suite, spa-style bathrooms with floor-to-ceiling windows.
          Upgraded suites add living rooms, dining areas and balconies with city
          views. A 3-bedroom suite sleeps up to 6 guests. Parking is included,
          as is a breakfast buffet. There's also an outdoor pool, a gym and a
          24/7 coffee shop, plus a lobby bar and a restaurant. An airport
          shuttle is available (fee).
          <br />
          <br />
          Telephone : (021) 27938999
          <br />
          Email : reservation@goodrichsuites.com
          <br />
          Address : Jl. Pangeran Antasari No.60, RT.5/RW.9, Cipete Utara, Kec.
          Kby. Baru, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12150
        </div>
      </div>

      <div
        style={{
          padding: "0 5%",
          marginBottom: "9rem",
        }}
      >
        <div className="tmj-hotel-img-row">
          <div className="tmj-hotel-img-column">
            <img src={Goodrich1} />
            {/* <img
              src={Sutasoma8}
            />
            <img
              src={Sutasoma3}
            /> */}
          </div>

          <div className="tmj-hotel-img-column">
            <img src={Goodrich2} />
            {/* <img
              src={Sutasoma7}
            />
            <img
              src={Sutasoma2}
            /> */}
          </div>

          <div className="tmj-hotel-img-column">
            <img src={Goodrich3} />
          </div>

          <div className="tmj-hotel-img-column">
            <img src={Goodrich4} />
            {/* <img
              src={Sutasoma7}
            /> */}
          </div>
        </div>
      </div>

      <div
        style={{
          display: "none",
        }}
      >
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          style={{
            padding: "0 15rem",
          }}
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
              }}
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
              }}
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
              }}
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={Sutasoma1}
                className="d-block w-100"
                alt="image hotel"
              />
            </div>
            <div className="carousel-item">
              <img
                src={Sutasoma2}
                className="d-block w-100"
                alt="image hotel"
              />
            </div>
            <div className="carousel-item">
              <img
                src={Sutasoma3}
                className="d-block w-100"
                alt="image hotel"
              />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
}
