import jumbotron from "../components/jumbotron";
import ContactImage from "../assets/image-contact.jpg";
export default function ContactUs() {
  return (
    <>
      {jumbotron()}
      <div className="container text-center mt-5">
        <div className="row flex-lg-row flex-lg-nowrap">
          <div className="col-lg-6">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7448.099614524417!2d105.852381!3d21.030693!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135abc000809ea7%3A0xbb2716ec0282371d!2sNgoc%20Son%20Temple!5e0!3m2!1sid!2sid!4v1678590724191!5m2!1sid!2sid"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              title="map"
            ></iframe>
          </div>
          <div className="col-lg-6 flex-lg-column text-start">
            <h5 className="mb-4">Contact Form</h5>
            <form action="" method="post">
              <div
                className="d-flex flex-column gap-3"
                style={{ fontSize: "0.9rem" }}
              >
                <div>
                  <p className="mb-0">Your Name</p>
                  <input className="w-100" />
                </div>
                <div>
                  <p className="mb-0">Your E-mail Address</p>
                  <input className="w-100" />
                </div>
                <div>
                  <p className="mb-0">Phone Number</p>
                  <input type="tel" className="w-100" />
                </div>
                <div>
                  <p className="mb-0">Your Message *</p>
                  <textarea name="" id="" rows="5" className="w-100"></textarea>
                </div>
                <div className="w-100">
                  <button
                    type="submit"
                    className="btn btn-outline-secondary rounded-0 m-auto d-block"
                    style={{ fontSize: "0.9rem" }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <h2>Contact us</h2>
        <div className="container text-center">
          <div className="row flex-lg-row flex-lg-nowrap">
            <div className="col-lg-6">
              <img src={ContactImage} alt="our team" className="img-fluid" />
            </div>
            <div className="col-lg-6 flex-lg-column text-start">
              <h6 className="mb-4">TANGO SALIDA DE SOL TEAM</h6>
              <p>Hotline: MS Alice +84 35 60 20 135 ( Whatsapp/zalo )</p>
              <p>
                Email:
                <a
                  href="mailto:vietnamtangomarathon@gmail.com"
                  className="text-decoration-none"
                >
                  vietnamtangomarathon@gmail.com
                </a>
                /
                <a
                  href="mailto:tangosalidadesol@gmail.com"
                  className="text-decoration-none"
                >
                  tangosalidadesol@gmail.com
                </a>
                | <br />
                Fanpage :
                <a
                  href="https://www.facebook.com/vietnamtangomarathon?mibextid=ZbWKwL"
                  className="text-decoration-none"
                >
                  Vietnam TANGO Marathon
                </a>
                | Group:
                <a
                  href="https://www.facebook.com/groups/vntangomarathon/"
                  className="text-decoration-none"
                >
                  VIETNAM TANGO SALIDA DEL SOL
                </a>
                | <br />
                Event:
                <a
                  href="https://www.facebook.com/events/858352528962309/?acontext=%7B%22ref%22%3A%2252%22%2C%22action_history%22%3A%22[%7B%5C%22surface%5C%22%3A%5C%22share_link%5C%22%2C%5C%22mechanism%5C%22%3A%5C%22share_link%5C%22%2C%5C%22extra_data%5C%22%3A%7B%5C%22invite_link_id%5C%22%3A820355495939321%7D%7D]%22%7D"
                  className="text-decoration-none"
                >
                  here
                </a>
              </p>
              <p>Website: https://tangosalidadesol.com/</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
