import ImgBanner2 from "../assets/banner-2.jpeg"
import DjBasil from "../assets/cast/basil1.jpg"
import DjCarlos from "../assets/cast/carlos1.jpg"
import DjLloyd from "../assets/cast/lloyd.jpeg"
import DjLuca from "../assets/cast/luca.jpeg"
import Agostina from "../assets/cast/agostina.jpeg"
import Agostina2 from "../assets/cast/agostina2.jpeg"



export default function schedules () {

    return (
      <div
        className="bg-dark text-light"
        id="schedules"
      >
        <div
          className="tmj-section-header"
        >
          Schedules
        </div>

        <div
          className="tmj-section-title mb-5"
        >
          Venue, The Tribrata Darmawangsa
        </div>


        


        <div
          className="tmj-schedules-padding"
        >
          
          {/* DAY ONE */}
          <div
            className="row mb-5 pb-5"
          >
            <div
              className="col-12 col-md-12 col-lg-12"
            >
              <div
                className="tmj-schedule-container"
              >
                
                <div
                  className="tmj-card-date"
                >
                  <div>
                    <div
                      className="date"
                    >
                      07
                    </div>
                    <div
                      className="day"
                    >
                      thu
                    </div>
                  </div>
                </div>

                <div>


                  {/* WORKSHOPS */}
                  {/* ========= */}

                  {/* <div
                    className="d-flex align-items-center tmj-card-list"
                  >
                    <div
                      className="tmj-label-hour d-flex justify-content-center align-items-center"
                    >
                      1h 15'
                    </div>

                    <div
                      className="tmj-list-schedule"
                    >
                      <div
                        className="date"
                      >
                        Thursday, Nov 9, 2023
                      </div>
                      <div
                        className="title"
                      >
                        Workshop
                      </div>
                      <div>
                        <div
                          className="d-inline-block"
                        >
                          <i className="bi bi-clock"></i>
                          <span
                            className="hour"
                          >
                            12:30 - 13:45
                          </span>
                          <span className="tmj-dot"></span>
                        </div>
                        <span
                          className="performer"
                        >
                          Sebastian Jimenez & Agostina Tarchini
                        </span>
                      </div>
                    </div>

                  </div> */}

                  {/* <div
                    className="d-flex align-items-center tmj-card-list"
                  >
                    
                    <div
                      className="tmj-label-hour d-flex justify-content-center align-items-center"
                    >
                      1h 15'
                    </div>

                    

                    <div
                      className="tmj-list-schedule"
                    >
                      <div
                        className="date"
                      >
                        Thursday, Nov 9, 2023
                      </div>
                      <div
                        className="title"
                      >
                        Workshop
                      </div>
                      <div>
                        <div
                          className="d-inline-block"
                        >
                          <i className="bi bi-clock"></i>
                          <span
                            className="hour"
                          >
                            14:15 - 15:30
                          </span>
                          <span className="tmj-dot"></span>
                        </div>
                        <span
                          className="performer"
                        >
                          Sebastian Jimenez & Agostina Tarchini
                        </span>
                      </div>
                    </div>

                  </div> */}

                  {/* ========= */}

                  <div
                    className="d-flex align-items-center tmj-card-list"
                  >
                    
                    <div
                      className="tmj-label-hour d-flex justify-content-center align-items-center"
                    >
                      3h 30'
                    </div>

                    
                    <div
                      className="tmj-list-schedule"
                    >
                      <div
                        className="date"
                      >
                        Thursday, Nov 7, 2024
                      </div>
                      <div
                        className="title"
                      >
                        Welcome Milonga
                      </div>
                      <div>
                        <div
                          className="d-inline-block"
                        >
                          <i className="bi bi-clock"></i>
                          <span
                            className="hour"
                          >
                            18:00 - 21:30
                          </span>
                          <span className="tmj-dot"></span>
                        </div>
                        {/* <span
                          className="performer"
                        >
                          DJ Lloyd Bediones
                        </span> */}
                      </div>
                    </div>

                  </div>

                  <div
                    className="flex-fill d-flex align-items-center  tmj-card-list"
                  >
                    
                    <div
                      className="tmj-label-hour d-flex justify-content-center align-items-center"
                    >
                      3h 30'
                    </div>

                    

                    <div
                      className="tmj-list-schedule"
                    >
                      <div
                        className="date"
                      >
                        Thursday, Nov 7, 2024
                      </div>
                      <div
                        className="title"
                      >
                        Night Milonga with Performances
                      </div>
                      <div>
                        <div
                          className="d-inline-block"
                        >
                          <i className="bi bi-clock"></i>
                          <span
                            className="hour"
                          >
                            21:30 - 01:00
                          </span>
                          <span className="tmj-dot"></span>
                        </div>
                        {/* <span
                          className="performer"
                        >
                          DJ Basil Nadela
                        </span> */}
                      </div>
                    </div>

                  </div>

                </div>

              </div>
            </div>

            {/* <div
              className="col-12 col-md-12 col-lg-4 ps-5 text-center"
            >

              <div
                className="me-3 d-inline-block tmj-profile-img-container"
              >
                <img
                  src={Agostina2}
                  style={{
                    width: "125px",
                    height: "125px",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </div>

              <div
                className="me-3 d-inline-block tmj-profile-img-container"
              >
                <img
                  // src="https://demo.curlythemes.com/pirouette/wp-content/uploads/sites/19/2016/03/classes-contemporary-300x300.jpg"
                  src={DjLloyd}
                  style={{
                    width: "125px",
                    height: "125px",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </div>

              <div
                className="me-3 d-inline-block tmj-profile-img-container"
              >
                <img
                  // src="https://demo.curlythemes.com/pirouette/wp-content/uploads/sites/19/2016/03/classes-world-1-300x300.jpg"
                  src={DjBasil}
                  style={{
                    width: "125px",
                    height: "125px",
                    objectFit: "cover",
                    objectPosition: "center 0px",
                  }}
                />
              </div>

            </div> */}

          </div>

          
          {/* DAY TWO */}
          <div
            className="row mb-5 pb-5"
          >
            <div
              className="col-12 col-md-12 col-lg-12"
            >
              <div
                className="tmj-schedule-container"
              >
                
                <div
                  className="tmj-card-date"
                >
                  <div>
                    <div
                      className="date"
                    >
                      08
                    </div>
                    <div
                      className="day"
                    >
                      Fri
                    </div>
                  </div>
                </div>

                <div>

                  {/* WORKSHOPs */}
                  {/* ========= */}

                  {/* <div
                    className="d-flex align-items-center tmj-card-list"
                  >
                    
                    <div
                      className="tmj-label-hour d-flex justify-content-center align-items-center"
                    >
                      1h 15'
                    </div>

                    
                    <div
                      className="tmj-list-schedule"
                    >
                      <div
                        className="date"
                      >
                        Friday, Nov 10, 2023
                      </div>
                      <div
                        className="title"
                      >
                        Workshop
                      </div>
                      <div>
                        <div
                          className="d-inline-block"
                        >
                          <i className="bi bi-clock"></i>
                          <span
                            className="hour"
                          >
                            12:30 - 13:45
                          </span>
                          <span className="tmj-dot"></span>
                        </div>
                        <span
                          className="performer"
                        >
                          Sebastian Jimenez & Agostina Tarchini
                        </span>
                      </div>
                    </div>

                  </div> */}

                  {/* <div
                    className="d-flex align-items-center tmj-card-list"
                  >
                    
                    <div
                      className="tmj-label-hour d-flex justify-content-center align-items-center"
                    >
                      1h 15'
                    </div>

                    

                    <div
                      className="tmj-list-schedule"
                    >
                      <div
                        className="date"
                      >
                        Friday, Nov 10, 2023
                      </div>
                      <div
                        className="title"
                      >
                        Workshop
                      </div>
                      <div>
                        <div
                          className="d-inline-block"
                        >
                          <i className="bi bi-clock"></i>
                          <span
                            className="hour"
                          >
                            14:15 - 15:30
                          </span>
                          <span className="tmj-dot"></span>
                        </div>
                        <span
                          className="performer"
                        >
                          Sebastian Jimenez & Agostina Tarchini
                        </span>
                      </div>
                    </div>

                  </div> */}

                  {/* ========= */}

                  <div
                    className="d-flex align-items-center tmj-card-list"
                  >
                    
                    <div
                      className="tmj-label-hour d-flex justify-content-center align-items-center"
                    >
                      3h 30'
                    </div>

                    

                    <div
                      className="tmj-list-schedule"
                    >
                      <div
                        className="date"
                      >
                        Friday, Nov 8, 2024
                      </div>
                      <div
                        className="title"
                      >
                        Grand Milonga
                      </div>
                      <div>
                        <div
                          className="d-inline-block"
                        >
                          <i className="bi bi-clock"></i>
                          <span
                            className="hour"
                          >
                            18:00 - 21:30
                          </span>
                          <span className="tmj-dot"></span>
                        </div>
                        {/* <span
                          className="performer"
                        >
                          DJ Carlos Churri
                        </span> */}
                      </div>
                    </div>

                  </div>

                  <div
                    className="flex-fill d-flex align-items-center  tmj-card-list"
                  >
                    
                    <div
                      className="tmj-label-hour d-flex justify-content-center align-items-center"
                    >
                      3h 30'
                    </div>

                    

                    <div
                      className="tmj-list-schedule"
                    >
                      <div
                        className="date"
                      >
                        Friday, Nov 8, 2024
                      </div>
                      <div
                        className="title"
                      >
                        Grand Milonga with Performances
                        {/* <span class="badge badge-info">Light</span> */}
                        {/* <span>
                          with Performances
                        </span> */}
                      </div>
                      <div>
                        <div
                          className="d-inline-block"
                        >
                          <i className="bi bi-clock"></i>
                          <span
                            className="hour"
                          >
                            21:30 - 01:00
                          </span>
                          <span className="tmj-dot"></span>
                        </div>
                        {/* <span
                          className="performer"
                        >
                          DJ Luca Lamberti
                        </span> */}
                      </div>
                    </div>

                  </div>

                </div>

              </div>
            </div>

            {/* <div
              className="col-12 col-md-12 col-lg-4 ps-5 text-center"
            >

              <div
                className="me-3 d-inline-block tmj-profile-img-container"
              >
                <img
                  // src="https://demo.curlythemes.com/pirouette/wp-content/uploads/sites/19/2016/03/classes-contemporary-300x300.jpg"
                  src={Agostina2}
                  style={{
                    width: "125px",
                    height: "125px",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </div>

              <div
                className="me-3 d-inline-block tmj-profile-img-container"
              >
                <img
                  // src="https://demo.curlythemes.com/pirouette/wp-content/uploads/sites/19/2016/03/classes-contemporary-300x300.jpg"
                  src={DjCarlos}
                  style={{
                    width: "125px",
                    height: "125px",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </div>

              <div
                className="me-3 d-inline-block tmj-profile-img-container"
              >
                <img
                  // src="https://demo.curlythemes.com/pirouette/wp-content/uploads/sites/19/2016/03/classes-world-1-300x300.jpg"
                  src={DjLuca}
                  style={{
                    width: "125px",
                    height: "125px",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </div>

            </div> */}

          </div>


          {/* DAY THREE */}
          <div
            className="row mb-5 pb-5"
          >
            <div
              className="col-12 col-md-12 col-lg-12"
            >
              <div
                className="tmj-schedule-container"
              >
                
                <div
                  className="tmj-card-date"
                >
                  <div>
                    <div
                      className="date"
                    >
                      09
                    </div>
                    <div
                      className="day"
                    >
                      sat
                    </div>
                  </div>
                </div>

                <div>

                  {/* WORKSHOPS */}
                  {/* ========= */}

                  {/* <div
                    className="d-flex align-items-center tmj-card-list"
                  >
                    
                    <div
                      className="tmj-label-hour d-flex justify-content-center align-items-center"
                    >
                      1h 15'
                    </div>

                    

                    <div
                      className="tmj-list-schedule"
                    >
                      <div
                        className="date"
                      >
                        Saturday, Nov 11, 2023
                      </div>
                      <div
                        className="title"
                      >
                        Workshop
                      </div>
                      <div>
                        <div
                          className="d-inline-block"
                        >
                          <i className="bi bi-clock"></i>
                          <span
                            className="hour"
                          >
                            12:30 - 13:45
                          </span>
                          <span className="tmj-dot"></span>
                        </div>
                        <span
                          className="performer"
                        >
                          Sebastian Jimenez & Agostina Tarchini
                        </span>
                      </div>
                    </div>

                  </div> */}

                  {/* <div
                    className="d-flex align-items-center tmj-card-list"
                  >
                    
                    <div
                      className="tmj-label-hour d-flex justify-content-center align-items-center"
                    >
                      1h 15'
                    </div>

                    

                    <div
                      className="tmj-list-schedule"
                    >
                      <div
                        className="date"
                      >
                        Saturday, Nov 11, 2023
                      </div>
                      <div
                        className="title"
                      >
                        Workshop
                      </div>
                      <div>
                        <div
                          className="d-inline-block"
                        >
                          <i className="bi bi-clock"></i>
                          <span
                            className="hour"
                          >
                            14:15 - 15:30
                          </span>
                          <span className="tmj-dot"></span>
                        </div>
                        <span
                          className="performer"
                        >
                          Sebastian Jimenez & Agostina Tarchini
                        </span>
                      </div>
                    </div>

                  </div> */}

                  {/* ========= */}

                  <div
                    className="d-flex align-items-center tmj-card-list"
                  >
                    
                    <div
                      className="tmj-label-hour d-flex justify-content-center align-items-center"
                    >
                      3h 30'
                    </div>

                    

                    <div
                      className="tmj-list-schedule"
                    >
                      <div
                        className="date"
                      >
                        Saturday, Nov 9, 2024
                      </div>
                      <div
                        className="title"
                      >
                        Milonga
                      </div>
                      <div>
                        <div
                          className="d-inline-block"
                        >
                          <i className="bi bi-clock"></i>
                          <span
                            className="hour"
                          >
                            18:00 - 21:30
                          </span>
                          <span className="tmj-dot"></span>
                        </div>
                        {/* <span
                          className="performer"
                        >
                          DJ Luca Lamberti
                        </span> */}
                      </div>
                    </div>

                  </div>

                  <div
                    className="flex-fill d-flex align-items-center  tmj-card-list"
                  >
                    
                    <div
                      className="tmj-label-hour d-flex justify-content-center align-items-center"
                    >
                      3h 30'
                    </div>

                    

                    <div
                      className="tmj-list-schedule"
                    >
                      <div
                        className="date"
                      >
                        Saturday, Nov 9, 2024
                      </div>
                      <div
                        className="title"
                      >
                        Farewell Milonga with Performances
                      </div>
                      <div>
                        <div
                          className="d-inline-block"
                        >
                          <i className="bi bi-clock"></i>
                          <span
                            className="hour"
                          >
                            21:30 - 01:00
                          </span>
                          <span className="tmj-dot"></span>
                        </div>
                        {/* <span
                          className="performer"
                        >
                          DJ Carlos Churri
                        </span> */}
                      </div>
                    </div>

                  </div>

                </div>

              </div>
            </div>

            {/* <div
              className="col-12 col-md-12 col-lg-4 ps-5 text-center"
            >

              <div
                className="me-3 d-inline-block tmj-profile-img-container"
              >
                <img
                  // src="https://demo.curlythemes.com/pirouette/wp-content/uploads/sites/19/2016/03/classes-contemporary-300x300.jpg"
                  src={Agostina2}
                  style={{
                    width: "125px",
                    height: "125px",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </div>

              <div
                className="me-3 d-inline-block tmj-profile-img-container"
              >
                <img
                  // src="https://demo.curlythemes.com/pirouette/wp-content/uploads/sites/19/2016/03/classes-contemporary-300x300.jpg"
                  src={DjLuca}
                  style={{
                    width: "125px",
                    height: "125px",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </div>

              <div
                className="me-3 d-inline-block tmj-profile-img-container"
              >
                <img
                  // src="https://demo.curlythemes.com/pirouette/wp-content/uploads/sites/19/2016/03/classes-world-1-300x300.jpg"
                  src={DjCarlos}
                  style={{
                    width: "125px",
                    height: "125px",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </div>

            </div> */}

          </div>
          

        </div>
















        <div
          // className="col-12 col-md-6"
          style={{
            padding: "0% 10% 8%",
            display: "none",
          }}
        >
          
          {/* DAY ONE */}
          <div
            className="row mb-5 pb-5"
          >
            <div
              className="col-12 col-md-auto col-lg-auto"
            >
              <div
                className="d-flex mb-4"
              >
                
                <div
                  className="tmj-card-date"
                >
                  <div>
                    <div
                      className="date"
                    >
                      02
                    </div>
                    <div
                      className="day"
                    >
                      mon
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    className="flex-fill ms-5 d-flex align-items-center"
                    style={{
                      borderBottom: "1px solid rgba(102,102,102,.1)",
                      height: "108px"
                    }}
                  >
                    
                    <div
                      className="tmj-label-hour d-flex justify-content-center align-items-center"
                    >
                      2h 30'
                    </div>

                    

                    <div
                      className="tmj-list-schedule"
                    >
                      <div
                        className="date"
                      >
                        Tuesday, Jan 3, 2023
                      </div>
                      <div
                        className="title"
                      >
                        Welcome Milonga
                      </div>
                      <div>
                        <i className="bi bi-clock"></i>
                        <span
                          className="hour"
                        >
                          18:00 - 21:30
                        </span>
                        <span className="tmj-dot"></span>
                        <span
                          className="performer"
                        >
                          DJ Carlos
                        </span>
                      </div>
                    </div>

                  </div>

                  <div
                    className="flex-fill ms-5 d-flex align-items-center"
                    style={{
                      borderBottom: "1px solid rgba(102,102,102,.1)",
                      height: "108px"
                    }}
                  >
                    
                    <div
                      className="tmj-label-hour d-flex justify-content-center align-items-center"
                    >
                      2h 30'
                    </div>

                    

                    <div
                      className="tmj-list-schedule"
                    >
                      <div
                        className="date"
                      >
                        Tuesday, Jan 3, 2023
                      </div>
                      <div
                        className="title"
                      >
                        Night Milonga
                      </div>
                      <div>
                        <i className="bi bi-clock"></i>
                        <span
                          className="hour"
                        >
                          21:30 - 01:00
                        </span>
                        <span className="tmj-dot"></span>
                        <span
                          className="performer"
                        >
                          DJ Luca
                        </span>
                      </div>
                    </div>

                  </div>

                </div>

              </div>
            </div>

            <div
              className="col-12 col-md-7 col-lg-5 ms-5"
            >

              <div
                style={{
                  width: "150px",
                  height: "150px",
                  padding: "12.5px",
                  boxShadow: "0 1rem 3rem -1rem rgba(0,0,0,.25)"
                }}
                className="me-3 d-inline-block"
              >
                <img
                  src="https://demo.curlythemes.com/pirouette/wp-content/uploads/sites/19/2016/03/classes-contemporary-300x300.jpg"
                  style={{
                    width: "100%"
                  }}
                />
              </div>

              <div
                style={{
                  width: "150px",
                  height: "150px",
                  padding: "12.5px",
                  boxShadow: "0 1rem 3rem -1rem rgba(0,0,0,.25)"
                }}
                className="me-3 d-inline-block"
              >
                <img
                  src="https://demo.curlythemes.com/pirouette/wp-content/uploads/sites/19/2016/03/classes-world-1-300x300.jpg"
                  style={{
                    width: "100%"
                  }}
                />
              </div>

            </div>
          </div>


          {/* DAY TWO */}
          <div
            className="row mb-5 pb-5 flex-row-reverse"
          >
            <div
              className="col-12 col-md-auto col-lg-auto"
            >
              <div
                className="d-flex mb-4 justify-content-end"
              >
                
                <div
                  className="tmj-card-date"
                >
                  <div>
                    <div
                      className="date"
                    >
                      02
                    </div>
                    <div
                      className="day"
                    >
                      mon
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    className="flex-fill ms-5 d-flex align-items-center"
                    style={{
                      borderBottom: "1px solid rgba(102,102,102,.1)",
                      height: "108px"
                    }}
                  >
                    
                    <div
                      className="tmj-label-hour d-flex justify-content-center align-items-center"
                    >
                      2h 30'
                    </div>

                    

                    <div
                      className="tmj-list-schedule"
                    >
                      <div
                        className="date"
                      >
                        Tuesday, Jan 3, 2023
                      </div>
                      <div
                        className="title"
                      >
                        Welcome Milonga
                      </div>
                      <div>
                        <i className="bi bi-clock"></i>
                        <span
                          className="hour"
                        >
                          18:00 - 21:30
                        </span>
                        <span className="tmj-dot"></span>
                        <span
                          className="performer"
                        >
                          DJ Carlos
                        </span>
                      </div>
                    </div>

                  </div>

                  <div
                    className="flex-fill ms-5 d-flex align-items-center"
                    style={{
                      borderBottom: "1px solid rgba(102,102,102,.1)",
                      height: "108px"
                    }}
                  >
                    
                    <div
                      className="tmj-label-hour d-flex justify-content-center align-items-center"
                    >
                      2h 30'
                    </div>

                    

                    <div
                      className="tmj-list-schedule"
                    >
                      <div
                        className="date"
                      >
                        Tuesday, Jan 3, 2023
                      </div>
                      <div
                        className="title"
                      >
                        Night Milonga
                      </div>
                      <div>
                        <i className="bi bi-clock"></i>
                        <span
                          className="hour"
                        >
                          21:30 - 01:00
                        </span>
                        <span className="tmj-dot"></span>
                        <span
                          className="performer"
                        >
                          DJ Luca
                        </span>
                      </div>
                    </div>

                  </div>

                </div>

              </div>
            </div>

            <div
              className="col-12 col-md-7 col-lg-5 text-end me-5"
            >

              <div
                style={{
                  width: "150px",
                  height: "150px",
                  padding: "12.5px",
                  boxShadow: "0 1rem 3rem -1rem rgba(0,0,0,.25)"
                }}
                className="me-3 d-inline-block"
              >
                <img
                  src="https://demo.curlythemes.com/pirouette/wp-content/uploads/sites/19/2016/03/classes-contemporary-300x300.jpg"
                  style={{
                    width: "100%"
                  }}
                />
              </div>

              <div
                style={{
                  width: "150px",
                  height: "150px",
                  padding: "12.5px",
                  boxShadow: "0 1rem 3rem -1rem rgba(0,0,0,.25)"
                }}
                className="me-3 d-inline-block"
              >
                <img
                  src="https://demo.curlythemes.com/pirouette/wp-content/uploads/sites/19/2016/03/classes-world-1-300x300.jpg"
                  style={{
                    width: "100%"
                  }}
                />
              </div>

            </div>
          </div>


          {/* DAY THREE */}
          <div
            className="row mb-5 pb-5"
          >
            <div
              className="col-12 col-md-auto col-lg-auto"
            >
              <div
                className="d-flex mb-4"
              >
                
                <div
                  className="tmj-card-date"
                >
                  <div>
                    <div
                      className="date"
                    >
                      02
                    </div>
                    <div
                      className="day"
                    >
                      mon
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    className="flex-fill ms-5 d-flex align-items-center"
                    style={{
                      borderBottom: "1px solid rgba(102,102,102,.1)",
                      height: "108px"
                    }}
                  >
                    
                    <div
                      className="tmj-label-hour d-flex justify-content-center align-items-center"
                    >
                      2h 30'aaaaa
                    </div>

                    

                    <div
                      className="tmj-list-schedule"
                    >
                      <div
                        className="date"
                      >
                        Tuesday, Jan 3, 2023
                      </div>
                      <div
                        className="title"
                      >
                        Welcome Milonga
                      </div>
                      <div>
                        <i className="bi bi-clock"></i>
                        <span
                          className="hour"
                        >
                          18:00 - 21:30
                        </span>
                        <span className="tmj-dot"></span>
                        <span
                          className="performer"
                        >
                          DJ Carlos
                        </span>
                      </div>
                    </div>

                  </div>

                  <div
                    className="flex-fill ms-5 d-flex align-items-center"
                    style={{
                      borderBottom: "1px solid rgba(102,102,102,.1)",
                      height: "108px"
                    }}
                  >
                    
                    <div
                      className="tmj-label-hour d-flex justify-content-center align-items-center"
                    >
                      2h 30'
                    </div>

                    

                    <div
                      className="tmj-list-schedule"
                    >
                      <div
                        className="date"
                      >
                        Tuesday, Jan 3, 2023
                      </div>
                      <div
                        className="title"
                      >
                        Night Milonga
                      </div>
                      <div>
                        <i className="bi bi-clock"></i>
                        <span
                          className="hour"
                        >
                          21:30 - 01:00
                        </span>
                        <span className="tmj-dot"></span>
                        <span
                          className="performer"
                        >
                          DJ Luca
                        </span>
                      </div>
                    </div>

                  </div>

                </div>

              </div>
            </div>

            <div
              className="col-12 col-md-7 col-lg-5 ms-5"
            >

              <div
                style={{
                  width: "150px",
                  height: "150px",
                  padding: "12.5px",
                  boxShadow: "0 1rem 3rem -1rem rgba(0,0,0,.25)"
                }}
                className="me-3 d-inline-block"
              >
                <img
                  src="https://demo.curlythemes.com/pirouette/wp-content/uploads/sites/19/2016/03/classes-contemporary-300x300.jpg"
                  style={{
                    width: "100%"
                  }}
                />
              </div>

              <div
                style={{
                  width: "150px",
                  height: "150px",
                  padding: "12.5px",
                  boxShadow: "0 1rem 3rem -1rem rgba(0,0,0,.25)"
                }}
                className="me-3 d-inline-block"
              >
                <img
                  src="https://demo.curlythemes.com/pirouette/wp-content/uploads/sites/19/2016/03/classes-world-1-300x300.jpg"
                  style={{
                    width: "100%"
                  }}
                />
              </div>

            </div>
          </div>
          

        </div>

      </div>
    )
}