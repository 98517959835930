import { useEffect, useState } from "react";
import axios from 'axios';

export default function Register() {

    let [email, setEmail] = useState("");
    let [passportName, setPassportName] = useState("");
    let [passportNumber, setPassportNumber] = useState("");
    let [phoneNumber, setPhoneNumber] = useState("");
    let [country, setCountry] = useState("");
    let [gender, setGender] = useState("");
    let [hotelReservation, setHotelReservation] = useState("");
    let [couples, setCouples] = useState("");
    let [paymentMethod, setPaymentMethod] = useState("");
    
    // CONDITIONAL LAYOUT
    let [isPaymentMethodOther, setIsPaymentMethodOther] = useState(false);
    let [isRegistering, setIsRegistering] = useState(false);
    let [isRegistered, setIsRegistered] = useState(false)

    useEffect(() => {
      // Update the document title using the browser API
      console.log("on page register");

      // axios.get("http://localhost:8080/customer")
      // .then(data => {
      //   console.log(data)
      // })
      // .catch(e => {
      //   console.log(e);
      // })

    }, []);

    let register = () => {

      setIsRegistering(true);

      console.log("REGISTER");
      let data = {
        email: email,
        facebook_name: passportName,
        phone_number: phoneNumber,
        passport_number: phoneNumber,
        country: country,
        event_option: "0",
        special_edition: "0",
        gender: gender,
        package_type: "",
        hotel_reservation: hotelReservation,
        couples: couples,
        payment_method: paymentMethod,
      };
      console.log(data);

      setTimeout(() => {
        setIsRegistering(false)
        setIsRegistered(true)
      }, 3000)


      // axios.post("http://localhost:8080/customer", data)
      // .then(data => {
      //   console.log(data);
      //   setIsRegistering(false);
      // })
      // .catch(e => {
      //   console.log(e);
      //   setIsRegistering(false)
      // })

    }

    return (
      <div
        className="row"
        style={{
          minHeight: "500px",
        }}
      >
        <div
          className="col-sm-12 col-lg-4 fs-2 text-cente position-relative justify-content-center align-items-center"
          style={{
            background: "rgb(241,243,247)",
            lineHeight: "35px",
          }}
        >
          <div className="sticky-top p-5 mt-5">
            <div
              className="px-5 mt-5 mx-0 text-montserrat light"
              style={{
                fontSize: "42px",
                lineHeight: "50px"
              }}
            >
              Jakarta Tango Marathon 2023
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-8 p-5">
          <div
            className="p-5 ms-5"
            style={{
              maxWidth: "400px"
            }}
          >
            <div className="fw-bold">Email</div>
            <div className="input-group mb-5">
              {/* <span className="input-group-text" id="basic-addon1">@</span> */}
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={email}
                onInput={(e) => {
                  // console.log("value text is ", e.target.value);
                  setEmail(e.target.value);
                }}
              />
            </div>

            <div className="fw-bold">Passport Name</div>
            <div className="input-group mb-5">
              {/* <span className="input-group-text" id="basic-addon1">@</span> */}
              <input
                type="text"
                className="form-control"
                placeholder="Passport Name"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={passportName}
                onInput={(e) => {
                  // console.log("value text is ", e.target.value);
                  setPassportName(e.target.value);
                }}
              />
            </div>

            <div className="fw-bold">Passport Number (For Safety Use)</div>
            <div className="input-group mb-5">
              {/* <span className="input-group-text" id="basic-addon1">@</span> */}
              <input
                type="text"
                className="form-control"
                placeholder="Passport Number"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={passportNumber}
                onInput={(e) => {
                  // console.log("value text is ", e.target.value);
                  setPassportNumber(e.target.value);
                }}
              />
            </div>

            <div>
              <div className="fw-bold">Phone Number (Whatsapp)</div>
              <div className="text-muted" style={{marginTop: "-0px"}}>example +6281234567</div>
            </div>
            <div className="input-group mb-5">
              {/* <span className="input-group-text" id="basic-addon1">@</span> */}
              <input
                type="text"
                className="form-control"
                placeholder="Phone Number"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={phoneNumber}
                onInput={(e) => {
                  // console.log("value text is ", e.target.value);
                  setPhoneNumber(e.target.value);
                }}
              />
            </div>

            <div className="fw-bold">Country</div>
            <div className="input-group mb-5">
              {/* <span className="input-group-text" id="basic-addon1">@</span> */}
              <input
                type="text"
                className="form-control"
                placeholder="Country"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={country}
                onInput={(e) => {
                  // console.log("value text is ", e.target.value);
                  setCountry(e.target.value);
                }}
              />
            </div>

            <div className="mb-5">
              <div className="fw-bold">Gender</div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="leader"
                  checked={gender == "leader" ? true : false}
                  onChange={e => {
                    // console.log("checkbox check ", e.target.checked);
                    if (e.target.checked) {
                      setGender(e.target.value);
                    } else {
                      setGender("");
                    }
                  }}
                />
                <label className="form-check-label" for="inlineCheckbox1">Leader</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="follower"
                  checked={gender == "follower" ? true : false}
                  onChange={e => {
                    // console.log("checkbox check ", e.target.checked);
                    if (e.target.checked) {
                      setGender(e.target.value);
                    } else {
                      setGender("");
                    }
                  }}
                />
                <label className="form-check-label" for="inlineCheckbox1">Follower</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="both"
                  checked={gender == "both" ? true : false}
                  onChange={e => {
                    // console.log("checkbox check ", e.target.checked);
                    if (e.target.checked) {
                      setGender(e.target.value);
                    } else {
                      setGender("");
                    }
                  }}
                />
                <label className="form-check-label" for="inlineCheckbox1">Both</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="couple"
                  checked={gender == "couple" ? true : false}
                  onChange={e => {
                    // console.log("checkbox check ", e.target.checked);
                    if (e.target.checked) {
                      setGender(e.target.value);
                    } else {
                      setGender("");
                    }
                  }}
                />
                <label className="form-check-label" for="inlineCheckbox1">Couple</label>
              </div>
            </div>

            <div className="mb-5">
              <div className="fw-bold">Hotel Reservation</div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="yes"
                  checked={hotelReservation == "yes" ? true : false}
                  onChange={e => {
                    // console.log("checkbox check ", e.target.checked);
                    if (e.target.checked) {
                      setHotelReservation(e.target.value);
                    } else {
                      setHotelReservation("");
                    }
                  }}
                />
                <label className="form-check-label" for="inlineCheckbox1">Yes</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="no"
                  checked={hotelReservation == "no" ? true : false}
                  onChange={e => {
                    // console.log("checkbox check ", e.target.checked);
                    if (e.target.checked) {
                      setHotelReservation(e.target.value);
                    } else {
                      setHotelReservation("");
                    }
                  }}
                />
                <label className="form-check-label" for="inlineCheckbox1">Another Hotel Yourself</label>
              </div>
            </div>

            <div className="fw-bold">Couple Notes/Your Partner</div>
            <div className="input-group mb-5">
              {/* <span className="input-group-text" id="basic-addon1">@</span> */}
              <input
                type="text"
                className="form-control"
                placeholder="Your Partner's Name"
                aria-label="Your Partner's Name"
                aria-describedby="basic-addon1"
                value={couples}
                onInput={(e) => {
                  // console.log("value text is ", e.target.value);
                  setCouples(e.target.value);
                }}
              />
            </div>
            

            <div className="mb-5">
              <div className="fw-bold">Payment Method</div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="paypal"
                  checked={paymentMethod == "paypal" ? true : false}
                  onChange={e => {
                    // console.log("checkbox check ", e.target.checked);
                    if (e.target.checked) {
                      setPaymentMethod(e.target.value);
                    } else {
                      setPaymentMethod("");
                    }
                  }}
                />
                <label className="form-check-label" for="inlineCheckbox1">Paypal</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="other"
                  checked={isPaymentMethodOther ? true : false}
                  onChange={e => {
                    // console.log("checkbox check ", e.target.checked);
                    if (e.target.checked) {
                      setIsPaymentMethodOther(true);
                      setPaymentMethod("");
                    } else {
                      setIsPaymentMethodOther(false);
                    }
                  }}
                />
                <label className="form-check-label" for="inlineCheckbox1">Other</label>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Payment Method"
                aria-label="Payment Method"
                aria-describedby="basic-addon1"
                value={paymentMethod}
                onInput={(e) => {
                  // console.log("value text is ", e.target.value);
                  setPaymentMethod(e.target.value);
                }}
              />
            </div>

            <div>We agree all the terms & condition of registration above</div>
            <div className="form-check mb-5">
              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
              <label className="form-check-label" for="inlineCheckbox1">Yes</label>
            </div>

            <button
              type="button"
              className="btn btn-warning btn-lg"
              style={{
                width: "150px"
              }}
              disabled={isRegistering || isRegistered}
              onClick={register}
            >

              {
                isRegistering ?
                <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                :
                ""
              }
              Submit

            </button>

            {
              isRegistered ?
              <div class="alert alert-success mt-4" role="alert">
                You Have Been Registered
              </div>
              : 
              ""
            }

          </div>
        </div>

      </div>
    );
  }