import React, { useState } from "react";


export default function Register() {

  const [message, setMessage] = useState("Hello, can I get info on Jakarta Tango Marathon 2024 Registration?")

  const sendMessage = () => {
    const url = `https://wa.me/+6287811021160?text=${message.replace(/ /g, "%20")}`;
    window.open(url, "_blank");
  }
  

  return (
    <div
      className="bg-dark text-light"
      style={{
        paddingBottom: "10rem",
      }}
      id="register"
    >
      <div className="tmj-section-header">Register</div>

      <div className="tmj-section-title text-light">
        
        {/* Registration will be open soon... */}
        For registration, please contact us via whatsapp
      </div>

      <div
        className="d-flex justify-content-center"
        style={{
          padding: "0 5%",
        }}
      >
        <button
          className="tmj-btn-register tmj-section-register-support-paragra fw-bol"
          onClick={() => { sendMessage() }}
        >
          <i class="bi bi-whatsapp me-3"></i>
          Send WhatsApp
        </button>
      </div>

    </div>
  );
}
