import LogoUrban from "../assets/logo-urban.jpg"
import LogoTangolicious from "../assets/logo-tangolicious.jpg"


export default function Host() {
  return (
    <div
      className="bg-dark text-light tmj-host"
    >
      <div className="tmj-section-header">Hosted By</div>

      <div
        className="row bg-dark mt-4"
        style={{
          padding: "0 5%",
        }}
      >
        <div
          className="col-12 col-md-6"
        >
          <div
            className="position-relative m-auto me-lg-5 mb-5"
            style={{
              width: "250px",
              height: "250px",
              borderRadius: "100%",
              overflow: "hidden",
              // paddingBottom: "100%",
              backgroundImage: `url(${LogoUrban})`,
              backgroundSize: "cover",
              backgroundPosition: "center top",
            }}
          >

          </div>
        </div>


        <div
          className="col-12 col-md-6"
        >
          <div
            className="position-relative m-auto ms-lg-5 mb-5"
            style={{
              width: "250px",
              height: "250px",
              borderRadius: "100%",
              overflow: "hidden",
              backgroundImage: `url(${LogoTangolicious})`,
              backgroundSize: "cover",
              backgroundPosition: "center top",
            }}
          >

          </div>
        </div>

      
      </div>
    </div>
  );
}
