import { useRef, useState, useEffect } from "react";
import ImgBanner from "../assets/banner.jpeg";
import Banner2024 from "../assets/banner2024.jpeg";
import Banner2024new from "../assets/banner-2024.jpeg";
import ReactDOM from 'react-dom';

export default function Banner() {

  const [y, setY] = useState();

  const myref = useRef();

  // const getPosition = () => {

  //   const y = myref.current.offsetTop;
  //   setY(y);
  // };

  // Get the position of the red box in the beginning
  useEffect(() => {
    // getPosition();
    // var rect = ReactDOM.findDOMNode(this);
    // console.log(rect.offsetTop);
    
    // const y = myref.current.offsetTop;
    // console.log("offset y is ", y);

    // const y = window.pageYOffset;
    // console.log("offset y is ", y);

  }, []);
  
  // Re-calculate X and Y of the red box when the window is resized by the user
  useEffect(() => {
    // window.addEventListener("resize", getPosition);
    

    // const {x,y} = myref.current.getBoundingClientRect();
    // console.log("x y ", x, y);
  });


  // console.log(`offset y = ${y}`);

  return (
    <div
      style={{
        // marginBottom: "-100px",
        paddingBottom: "100px",
        overflow: "hidden",
        // background: "rgb(35,35,35)",
        position: "relative",
      }}
      className="d-flex justify-content-center align-items-center"
      id="banner"
    >


      <div
        style={{
          position: "absolute",
          top: "0%",
          left: "0%",
          width: "100%",
          height: "100%",
        }}
        className="bg-dark"
      >

      </div>

      <div
        style={{
          position: "absolute",
          top: "60%",
          left: "0%",
          width: "100%",
          height: "40%",
        }}
        className="bg-dark"
      >

      </div>


      <div
        style={{
          zIndex: "10",
          // padding: "12.5px",
          boxShadow: "0 1rem 3rem -1rem rgba(0,0,0,.25)"
        }}
      >
        <img
          src={Banner2024new}
          style={{
            // width: "60%",
            maxWidth: "800px",
            // height: "80%",
            objectFit: "cover",
            boxShadow: "rgba(0, 0, 0, 0.25) 0px 17px 51px -17px",
            zIndex: "2",
            width: "100%",
          }}
        />
      </div>
    </div>
  )
}